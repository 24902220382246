import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogProps,
  Grow,
  IconButton,
  Theme,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

interface ICustomDialogProps extends DialogProps {
  allowBackdropClick?: boolean;
  isConfirmation?: boolean;
  showClose?: boolean;
}

const useCustomDialogStyles = makeStyles<Theme, ICustomDialogProps>(
  (theme: Theme) => ({
    root: ({ isConfirmation }) => ({
      textAlign: isConfirmation ? "center" : undefined,
      "& .MuiDialogTitle-root": {
        marginBottom: theme.spacing(1),
        color: theme.palette.common.white,
        fontSize: theme.typography.subtitle2.fontSize,
        padding: theme.spacing(1.5, 3, 1),
      },
      "& .MuiDialogContent-root": {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        "&:first-of-type": {
          boxShadow: "inset 0px 3px 5px -2px rgba(0,0,0,0.5)",
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
        },
        "&:not(:first-of-type)": {
          paddingTop: theme.spacing(2),
        },
      },
      "& .MuiDialogActions-root": {
        padding: isConfirmation
          ? theme.spacing(1, 5, 2)
          : theme.spacing(1.5, 3),
        borderTop: isConfirmation
          ? undefined
          : `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.common.white,
      },
    }),
  })
);

function CustomDialog(props: ICustomDialogProps) {
  const {
    children,
    onClose,
    isConfirmation,
    showClose,
    allowBackdropClick,
    ...other
  } = props;
  const { root } = useCustomDialogStyles(props);
  return (
    <Dialog
      {...other}
      TransitionComponent={Grow}
      className={root}
      onClose={(e, reason) => {
        onClose &&
          (reason !== "backdropClick" || allowBackdropClick) &&
          onClose(e, reason);
      }}
    >
      {showClose && typeof onClose === "function" && (
        <Box sx={{ position: "absolute", right: 4, top: 2, color: "white" }}>
          <IconButton
            // color="inherit"
            onClick={(e) => onClose(e, "escapeKeyDown")}
          >
            <CloseRounded sx={{ color: "white" }} />
          </IconButton>
        </Box>
      )}
      {children}
    </Dialog>
  );
}

CustomDialog.defaultProps = {
  showClose: true,
};

export default CustomDialog;
