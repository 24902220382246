import { MenuItem, TextField, Button, Stack } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { styled } from "@mui/material";
import { TComparisonMode, useFromToContext } from "Providers/FromToProvider";
import { friendlyDate } from "Helpers/dates";
import { useUpdateEffect } from "react-use";
import AdvancedDatePicker from "features/analytics/components/AdvancedDatePicker";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  height: 36,
  display: "flex",
  alignItems: "center",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: theme.spacing(2),
  minWidth: 10,
}));

const options = [
  "Last 7 Days",
  "Last 14 Days",
  "Last 30 Days",
  "Last 60 Days",
  "Last 90 Days",
  "Last 120 Days",
];

interface IDatePickerDropdownProps {
  label: string;
  size?: "small" | "medium";
}

function DatePickerDropdown({
  label,
  size = "medium",
}: IDatePickerDropdownProps) {
  const {
    from,
    setFrom,
    to,
    setTo,
    lastNDays,
    setLastNDays,
    comparison,
    setComparison,
    comparisonMode,
    setComparisonMode,
    cFrom,
    setCFrom,
  } = useFromToContext();
  const [value, setValue] = useState("");
  const [advancedFrom, setAdvancedFrom] = useState<moment.Moment | null>(from);
  const [advancedTo, setAdvancedTo] = useState<moment.Moment | null>(to);
  const [advancedComparison, setAdvancedComparison] =
    useState<boolean>(comparison);
  const [advancedComparisonMode, setAdvancedComparisonMode] =
    useState<TComparisonMode>(comparisonMode);
  const [advancedCFrom, setAdvancedCFrom] = useState<moment.Moment | null>(
    cFrom
  );
  const [advancedLastNDays, setAdvancedLastNDays] = useState<number | null>(
    lastNDays
  );
  const [custom, setCustom] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const setDate = (value: string) => {
    if (options.includes(value)) {
      setFrom(null);
      setTo(null);
      setAdvancedFrom(null);
      setAdvancedTo(null);
    }
    switch (value) {
      case options[0]:
        setLastNDays(7);
        return;
      case options[1]:
        setLastNDays(14);
        return;
      case options[2]:
        setLastNDays(30);
        return;
      case options[3]:
        setLastNDays(60);
        return;
      case options[4]:
        setLastNDays(90);
        return;
      case options[5]:
        setLastNDays(120);
        return;
    }
  };

  useEffect(() => {
    setCustom(null);
    if (lastNDays) {
      switch (lastNDays) {
        case 7:
          setValue(options[0]);
          return;
        case 14:
          setValue(options[1]);
          return;
        case 30:
          setValue(options[2]);
          return;
        case 60:
          setValue(options[3]);
          return;
        case 90:
          setValue(options[4]);
          return;
        case 120:
          setValue(options[5]);
          return;
      }
    } else {
      setValue("custom");
      setCustom(`${friendlyDate(from || "")} - ${friendlyDate(to || "")}`);
    }
  }, [from, to, lastNDays]);

  const onConfirm = useCallback(() => {
    setFrom(advancedFrom);
    setTo(advancedTo);
    setOpen(false);
    setComparison(advancedComparison);
    setComparisonMode(advancedComparisonMode);
    setCFrom(advancedCFrom);
    setLastNDays(advancedLastNDays);
  }, [
    advancedComparison,
    advancedFrom,
    advancedTo,
    advancedComparisonMode,
    advancedCFrom,
    advancedLastNDays,
    setComparison,
    setComparisonMode,
    setFrom,
    setLastNDays,
    setTo,
    setCFrom,
  ]);

  useUpdateEffect(() => {
    if (!open) {
      setAdvancedComparison(comparison);
      setAdvancedComparisonMode(comparisonMode);
      setAdvancedCFrom(cFrom);
      setAdvancedLastNDays(lastNDays);
      setAdvancedFrom(from);
      setAdvancedTo(to);
    }
  }, [comparison, comparisonMode, cFrom, lastNDays, from, to, open]);

  return (
    <>
      <Stack direction="row" alignItems={"center"}>
        <StyledTextField
          fullWidth
          select
          label={label}
          value={value}
          size={size}
          onChange={(e) => setDate(e.target.value)}
        >
          {options.map((o) => (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ))}
          {custom && <MenuItem value="custom">{custom}</MenuItem>}
        </StyledTextField>
        <StyledButton onClick={() => setOpen(true)}>
          <CalendarMonthIcon sx={{ fontSize: 24 }} />
        </StyledButton>
      </Stack>

      <AdvancedDatePicker
        open={open}
        setOpen={setOpen}
        onSubmit={onConfirm}
        from={advancedFrom}
        setFrom={setAdvancedFrom}
        to={advancedTo}
        setTo={setAdvancedTo}
        comparison={advancedComparison}
        setComparison={setAdvancedComparison}
        comparisonMode={advancedComparisonMode}
        setComparisonMode={setAdvancedComparisonMode}
        cFrom={advancedCFrom}
        setCFrom={setAdvancedCFrom}
        lastNDays={advancedLastNDays}
        setLastNDays={setAdvancedLastNDays}
      />
    </>
  );
}

export default DatePickerDropdown;
