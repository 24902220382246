import { Box, Stack, Switch, TextField, Typography } from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { hasAttributionMetric } from "features/analytics";

interface IChartBuilderDisplayOptionsProps {
  features?: ("rows" | "total" | "attribution")[];
}

function ChartBuilderDisplayOptions({
  features,
}: IChartBuilderDisplayOptionsProps) {
  const { chart, setChart } = useReportBuilderContext();

  return (
    <AccordianChartBuilderSidebarBlock
      title={`Display Options`}
      subTitle="Configure options on how your table should be presented (does not effect exports)"
    >
      <Stack spacing={2}>
        {(!features || features.includes("rows")) && (
          <TextField
            type="number"
            label="Rows Per Page"
            fullWidth
            size="small"
            value={chart.displayOptions?.rowsPerPage}
            onChange={(e) =>
              setChart(
                produce(chart, (draft) => {
                  if (!draft.displayOptions) {
                    draft.displayOptions = {};
                  }

                  draft.displayOptions.rowsPerPage =
                    e.target.value !== "" ? Number(e.target.value) : undefined;
                })
              )
            }
          />
        )}
        {(!features || features.includes("total")) && (
          <StyledFlexBox compact sx={{ maxWidth: 300 }}>
            <Box display="flex" alignItems="center">
              <Switch
                checked={chart.displayOptions?.showTotals}
                onChange={(e, checked) =>
                  setChart((chart) =>
                    produce(chart, (draft) => {
                      if (!draft.displayOptions) {
                        draft.displayOptions = {};
                      }
                      draft.displayOptions.showTotals = checked;
                    })
                  )
                }
              />
              <Typography
                color={
                  chart.displayOptions?.showTotals ? "primary" : "secondary"
                }
              >
                Show Totals
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={
                "Adds total row. Note this the total across your dimensions and ignores the limit."
              }
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        )}
        {(!features || features.includes("attribution")) &&
          hasAttributionMetric(chart) && (
            <StyledFlexBox compact sx={{ maxWidth: 300 }}>
              <Box display="flex" alignItems="center">
                <Switch
                  checked={chart.displayOptions?.noAttributionToggle}
                  onChange={(e, checked) =>
                    setChart((chart) =>
                      produce(chart, (draft) => {
                        if (!draft.displayOptions) {
                          draft.displayOptions = {};
                        }
                        draft.displayOptions.noAttributionToggle = checked;
                      })
                    )
                  }
                />
                <Typography
                  color={
                    chart.displayOptions?.noAttributionToggle
                      ? "primary"
                      : "secondary"
                  }
                >
                  Hide Attribution Toggle
                </Typography>
              </Box>
              <ClickableTooltip
                placement="right"
                text={
                  "Removes the toggle to select between different attribution models."
                }
                icon={<StyledHelp />}
              />
            </StyledFlexBox>
          )}
      </Stack>
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderDisplayOptions;
