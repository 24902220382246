import { Typography } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import { useMemo } from "react";

interface IShopifyInstructionsProps {
  incUrl: string;
  webhookUrl: string;
}

function ShopifyInstructions({
  incUrl,
  webhookUrl,
}: IShopifyInstructionsProps) {
  const code = useMemo(
    () => `// Step 1. Initialize the JavaScript pixel SDK (make sure to exclude HTML)
if (
  window.location.pathname.includes("/checkouts/") &&
  !window.location.pathname.includes("/thank_you")
) {
  const script = document.createElement("script");
  script.setAttribute(
    "src",
    "${incUrl}"
  );
  script.setAttribute("async", "");
  document.head.appendChild(script);
}

const waitForOnWindow = (variableName, callback, interval, timeout) => {
  const startTime = Date.now();

  function checkAvailability() {
    const variable = window[variableName];

    if (variable) {
      callback(null, variable);
    } else if (Date.now() - startTime >= timeout) {
      callback(
        new Error(
          variableName + " did not become available within the timeout period."
        )
      );
    } else {
      setTimeout(checkAvailability, interval);
    }
  }

  checkAvailability();
};

// Step 2. Subscribe to customer events with analytics.subscribe(), and add tracking
analytics.subscribe("page_viewed", (event) => {
  waitForOnWindow("Incendium", (error) => {
    if (error) {
      console.error(error.message);
    } else {
      Incendium.runIncendium(
        false,
        "shopify-pageview",
        event.context.document.location.href
      );
    }
  });
});`,
    [incUrl]
  );
  return (
    <div>
      <Typography variant="subtitle1">1. Sales webhook</Typography>
      <Typography variant="body1">
        To setup webhooks in the shopify app, go to settings {"->"}{" "}
        notifications {"->"} webhooks
      </Typography>
      <Typography variant="body1" gutterBottom>
        Click create webhook and populate these options:
      </Typography>
      <Typography variant="body1">event : order payment</Typography>
      <Typography variant="body1">format: JSON</Typography>
      <Typography variant="body1">URL :{webhookUrl}</Typography>
      <Typography variant="body1">
        version: 2024-04 (the latest stable version)
      </Typography>

      <Typography variant="subtitle1" mt={2}>
        2. Adding the pixel to the checkout process
      </Typography>
      <Typography variant="body1">
        Shopify doesn't include 3rd party tracking on the checkout by default.{" "}
      </Typography>
      <Typography variant="body1">
        To set up the Incendium pixel for the checkout, go to settings {"->"}{" "}
      </Typography>
      <Typography variant="body1">
        customer events and click 'add custom pixel'.
      </Typography>

      <Typography variant="body1">
        Name it anything you like (e.g Incendium Pixel)
      </Typography>
      <Typography variant="body1" marginBottom={2}>
        In the text area, add this code and click 'save':
      </Typography>

      <AceEditor
        mode={"javascript"}
        theme="monokai"
        name={"test"}
        fontSize={12}
        width={"100%"}
        tabSize={4}
        wrapEnabled
        showPrintMargin={false}
        value={code}
        setOptions={{
          useWorker: false,
        }}
      />
    </div>
  );
}

export default ShopifyInstructions;
