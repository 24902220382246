/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiMetricFromJSON, ApiMetricToJSON, } from './ApiMetric';
export function ApiTableGroupingFromJSON(json) {
    return ApiTableGroupingFromJSONTyped(json, false);
}
export function ApiTableGroupingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'metrics': !exists(json, 'metrics') ? undefined : (json['metrics'].map(ApiMetricFromJSON)),
    };
}
export function ApiTableGroupingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'metrics': value.metrics === undefined ? undefined : (value.metrics.map(ApiMetricToJSON)),
    };
}
