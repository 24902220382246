import {
  ApiChartType,
  ApiChartYAxisKey,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GlassCard, { IGlassCardProps } from "Components/GlassCard/GlassCard";
import ReportBubbleTitle from "..//components/ReportBubbleTitle";
import { IChart, TChartData } from "Interfaces";
import { memo, ReactNode, useEffect, useMemo, useState } from "react";
import { useContainerQuery } from "react-container-query";
import { useUpdateEffect } from "react-use";
import clsx from "clsx";
import Loading from "Components/Loading/Loading";
import { IFromToChartOutput } from "Providers/FromToProvider";
import { AttributionType } from "..//types/types";
import { AnimatePresence, motion } from "framer-motion";
import { FilterAlt, MoreHoriz } from "@mui/icons-material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import {
  filterNonTrendDimensions,
  filterTrendDimensions,
  getFirstMetricFromY,
  getMetricByIndex,
  hasAttributionMetric,
} from "../utils/utils";
import { metricConfigByName, metricToName } from "../services/metricsFormatter";
import { dimensionToName } from "../services/dimensionsFormatter";
import AnalyticsAttributionSwitch from "../components/AnalyticsAttributionSwitch";
import AnalyticsCardFiltersTooltip from "../components/AnalyticsCardFiltersTooltip";
import AnalyticsChart from "../components/AnalyticsChart";
import AnalyticsCardToolBar from "../components/AnalyticsCardToolBar";
import AnalyticsFilterDropdown from "../components/AnalyticsFilterDropdown";
import AnalyticsAttributionSidebar from "../components/AnalyticsAttributionSidebar";

interface IAnalyticsCardProps extends Omit<IGlassCardProps, "onClick"> {
  chart: IChart;
  onChangeMetric?: (m: ApiMetric) => void;
  onChangeDimension?: (m: ApiDimension) => void;
  metricsOverride?: ApiMetric[]; // used if we want to grab different metrics than specified in the selectedMetric
  customDataFn?: (arr: TChartData[]) => TChartData[]; // only working wirth tables at the moment
  defaultFirstFilter?: { [key in ApiDimension]?: string }; //used when using displayoptions.filterableDropdowns to pre select value
  overrideDate?: IFromToChartOutput;
  backgroundColor?: string;
  onModelsChange?: (models: AttributionType[]) => void;
  onClick?: { [field: string]: (v: string, o?: string) => void };
  customRightTop?: React.ReactNode;
  customFullRight?: React.ReactNode;
  customTitleFn?: (d: ApiDimension, m: ApiMetric) => string; // params are selected dimension and metric
  leadId?: number;
  onDataChange?: (arr: TChartData[], comp?: TChartData[]) => void;
  onExport?: () => Promise<void>;
  onEdit?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  noToolbar?: boolean;
  body?: ReactNode;
}

const query = {
  sm: {
    minWidth: 0,
    maxWidth: 265,
  },
};

function AnalyticsCard({
  chart,
  onChangeMetric,
  onChangeDimension,
  metricsOverride,
  customDataFn,
  defaultFirstFilter,
  overrideDate,
  backgroundColor,
  onModelsChange,
  onClick,
  customRightTop,
  customFullRight,
  customTitleFn,
  onDataChange,
  boxProps,
  onExport,
  onEdit,
  onDelete,
  noToolbar,
  body,
  ...glass
}: IAnalyticsCardProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const [filterOpen, setFFilterOpen] = useState(false);
  const [parsedChart, setParsedChart] = useState<IChart>(chart);
  const [filtersLoaded, setFiltersLoaded] = useState(
    (chart.displayOptions?.filterDropdowns || []).length === 0
  ); // if we require filters then we need to wait for them
  const [params, containerRef] = useContainerQuery(query, {});
  const [showLabel, setShowLabel] = useState(
    chart.displayOptions?.showLabels || false
  );
  const [showToolBar, setShowToolBar] = useState(false);

  const [selectedMetric, setSelectedMetric] = useState<ApiMetric>(
    getFirstMetricFromY(chart?.yAxisKeys) || ApiMetric.METRIC_NOT_SET
  );

  useUpdateEffect(() => {
    setSelectedMetric(
      getFirstMetricFromY(chart?.yAxisKeys) || ApiMetric.METRIC_NOT_SET
    );
  }, [getFirstMetricFromY(chart?.yAxisKeys)]);

  const firstNonTrendDimension = useMemo(() => {
    const filtered = filterNonTrendDimensions(
      chart?.dimension as ApiDimension[]
    );
    return filtered[0];
  }, [chart?.dimension]);

  const trendDimensions = useMemo(
    () => filterTrendDimensions(chart?.dimension as ApiDimension[]),
    [chart.dimension]
  );

  const [selectedDimension, setSelectedDimension] = useState<ApiDimension>(
    firstNonTrendDimension
      ? firstNonTrendDimension
      : ApiDimension.DIMENSION_NOT_SET
  );

  useUpdateEffect(() => {
    setSelectedDimension(
      firstNonTrendDimension
        ? firstNonTrendDimension
        : ApiDimension.DIMENSION_NOT_SET
    );
  }, [firstNonTrendDimension]);

  const availableMetrics = useMemo(
    () => chart.displayOptions?.availableMetrics || [],
    [chart.displayOptions]
  );
  const availableDimensions = useMemo(
    () => chart.displayOptions?.availableDimensions || [],
    [chart.displayOptions]
  );
  const filterableDropdowns = useMemo(
    () => chart.displayOptions?.filterDropdowns || [],
    [chart.displayOptions]
  );

  useEffect(() => {
    let displayOptions = chart.displayOptions;
    if (!displayOptions && showLabel) {
      displayOptions = {
        showLabels: true,
      };
    } else if (displayOptions) {
      displayOptions = {
        ...displayOptions,
        showLabels: showLabel,
      };
    }

    // overrides only work when using available metrics / dimensions
    setParsedChart({
      ...chart,
      displayOptions,
      dimension:
        availableDimensions.length > 0
          ? [...trendDimensions, selectedDimension]
          : chart.dimension,
      yAxisKeys:
        (chart.displayOptions?.availableMetrics || []).length > 0
          ? [
              {
                key: "l",
                fields: metricsOverride || [selectedMetric],
                chart: (chart.yAxisKeys[0] as ApiChartYAxisKey).chart,
                stackId: (chart.yAxisKeys[0] as ApiChartYAxisKey).stackId,
              },
            ]
          : chart.yAxisKeys,
    });
  }, [
    chart,
    selectedMetric,
    selectedDimension,
    metricsOverride,
    showLabel,
    trendDimensions,
    availableDimensions,
  ]);

  useUpdateEffect(() => {
    if (onChangeMetric) {
      onChangeMetric(selectedMetric);
    }
  }, [selectedMetric]);
  useUpdateEffect(() => {
    if (onChangeDimension) {
      onChangeDimension(selectedDimension);
    }
  }, [selectedDimension]);

  const hasMultipleAttribution = useMemo(() => {
    let attributionMetrics = new Set();
    chart.yAxisKeys.forEach((y) => {
      if (typeof y === "string") {
        return;
      }

      const valuesToAdd = (y.fields || [])
        .map((f) => metricConfigByName(f as ApiMetric)?.attribtionType)
        .filter((f) => f);

      valuesToAdd.forEach((value) => {
        attributionMetrics.add(value);
      });
    });
    return attributionMetrics.size > 1;
  }, [chart.yAxisKeys]);

  const numberOfDropdowns = useMemo(
    () =>
      availableMetrics.length +
      availableDimensions.length +
      filterableDropdowns.length,
    [availableMetrics, availableDimensions, filterableDropdowns]
  );

  const chartTitle = useMemo(
    () =>
      customTitleFn
        ? customTitleFn(selectedDimension, selectedMetric)
        : availableMetrics.length > 0 && chart.name?.includes(" by")
        ? `${chart.name} ${metricToName(selectedMetric)}`
        : availableDimensions.length > 0 && chart.name?.includes(" by")
        ? `${chart.name} ${dimensionToName(selectedDimension)}`
        : chart.name,
    [
      customTitleFn,
      chart.name,
      selectedDimension,
      selectedMetric,
      availableMetrics,
      availableDimensions,
    ]
  );

  useUpdateEffect(() => {
    if (!fullScreen) {
      setShowToolBar(false);
    }
  }, [fullScreen]);

  const renderBody = (white?: boolean) => {
    return (
      <>
        <Stack
          direction={"row"}
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          <GlassCard
            boxProps={
              boxProps || {
                px: 2,
                pt: 2.5,
                pb: chart.type === ApiChartType.TABLE ? 6 : 4.5,
              }
            }
            // backgroundColor={backgroundColor}
            sx={{
              overflow: "hidden",
              width: "100%",
              position: "relative",
              background: white ? "white" : undefined,
            }}
            overflow={"hidden"}
            {...glass}
          >
            <Stack
              direction={"row"}
              justifyContent="space-between"
              sx={{
                height: "100%",
                width: "100%",
                minHeight: fullScreen ? "50vh" : undefined,
              }}
            >
              <Stack
                spacing={parsedChart.displayOptions?.noLegend ? 2 : 0}
                flex={1}
              >
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={2}
                  mb={1}
                >
                  <Stack
                    spacing={2}
                    sx={{
                      width: "100%",
                      flex: numberOfDropdowns > 0 ? `0 1 50%` : 1,
                    }}
                    mb={
                      !chart.displayOptions?.noAttributionToggle &&
                      hasAttributionMetric(chart)
                        ? 1
                        : 0
                    }
                  >
                    {!chart.displayOptions?.noTitle && (
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ lineHeight: 1.2, textWrap: "pretty" }}
                        >
                          {chartTitle}
                        </Typography>
                        {chart.description && (
                          <Typography
                            variant="body2"
                            color={"secondary"}
                            sx={{ lineHeight: 1.2 }}
                            fontWeight={500}
                          >
                            {chart.description}
                          </Typography>
                        )}
                      </Box>
                    )}

                    {!chart.displayOptions?.noAttributionToggle &&
                      hasAttributionMetric(chart) && (
                        <Box
                          component={motion.div}
                          initial={{ opacity: 0, x: -40 }}
                          exit={{ opacity: 0, x: -40 }}
                          animate={{ opacity: 1, x: 0 }}
                        >
                          <AnalyticsAttributionSwitch
                            chart={parsedChart}
                            setChart={setParsedChart}
                            multiple={hasMultipleAttribution}
                            onModelsChange={onModelsChange}
                          />
                        </Box>
                      )}
                  </Stack>
                  {customRightTop}
                  {(chart.displayOptions?.fullAttributesList ||
                    numberOfDropdowns > 0) && (
                    <Stack
                      direction="row"
                      spacing={2}
                      mb={1}
                      ref={containerRef}
                      justifyContent={"flex-end"}
                      sx={{
                        maxWidth: "50%",
                        minWidth: "40%",
                        flex: 1,

                        "&.sm":
                          availableDimensions.length > 1
                            ? {
                                flexDirection: "column",
                                alignItems: "flex-end",
                                justifyContent: "flex-start",
                                "& > div:first-child": {
                                  marginBottom: 2,
                                },
                              }
                            : {},
                      }}
                      className={clsx(params)}
                    >
                      {(chart.displayOptions?.fullAttributesList || []).length >
                        0 && (
                        <Button
                          onClick={() => setFFilterOpen(true)}
                          size="small"
                          startIcon={<FilterAlt />}
                        >
                          Add Filters
                        </Button>
                      )}
                      {availableDimensions.length > 0 && (
                        <TextField
                          select
                          fullWidth
                          size="small"
                          sx={{ maxWidth: "100%", minWidth: 150, width: 200 }}
                          value={selectedDimension}
                          label="change dimension"
                          onChange={(e) => {
                            setSelectedDimension(
                              e.target.value as ApiDimension
                            );
                          }}
                        >
                          {(availableDimensions || []).map((dimension) => (
                            <MenuItem key={dimension} value={dimension}>
                              {dimensionToName(dimension)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {availableMetrics.length > 0 && (
                        <TextField
                          select
                          fullWidth
                          size="small"
                          sx={{ maxWidth: "100%", minWidth: 150, width: 200 }}
                          value={selectedMetric}
                          label="change metric"
                          onChange={(e) => {
                            setSelectedMetric(e.target.value as ApiMetric);
                          }}
                        >
                          {(availableMetrics || []).map((metric) => (
                            <MenuItem key={metric} value={metric}>
                              {metricToName(metric)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {filterableDropdowns.length > 0 &&
                        (filterableDropdowns || []).map((f) => (
                          <AnalyticsFilterDropdown
                            key={f}
                            dimension={f}
                            setChart={setParsedChart}
                            defaultFirstFilter={defaultFirstFilter}
                            isReady={() => setFiltersLoaded(true)}
                          />
                        ))}
                    </Stack>
                  )}
                  {chart.attributes.length > 0 &&
                    !chart.displayOptions?.noFilters && (
                      <AnalyticsCardFiltersTooltip
                        title={`${chart.name} Attributes`}
                        filters={chart.attributes}
                      />
                    )}
                </Stack>

                {chart.type === ApiChartType.BUBBLE && (
                  <ReportBubbleTitle
                    metric={getMetricByIndex(2, chart.yAxisKeys) || ""}
                    showLabel={showLabel}
                    setShowLabel={setShowLabel}
                  />
                )}
                {filtersLoaded ? (
                  <Box
                    sx={{
                      flex: "1 1 auto",
                      display: "flex",
                      overflowY: "visible",
                      overflowX: "visible",
                      justifyContent: "center",
                      minHeight: 0,
                      height: "100%",
                    }}
                  >
                    {body ? (
                      <>{body}</>
                    ) : (
                      <AnalyticsChart
                        chart={parsedChart}
                        customDataFn={customDataFn}
                        overrideDate={overrideDate}
                        onClick={onClick}
                        onDataChange={onDataChange}
                        tableSx={{
                          bottom: -47,
                          top: 10,
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  <Loading text="Loading Filters" />
                )}
              </Stack>
              {customFullRight}
            </Stack>
          </GlassCard>
          {!noToolbar && (
            <AnimatePresence mode="wait">
              {showToolBar ? (
                <AnalyticsCardToolBar
                  setOpen={setShowToolBar}
                  fullScreen={fullScreen}
                  setFullScreen={setFullScreen}
                  chart={chart}
                  onExport={onExport}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ) : (
                <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 5,
                      padding: 0.5,
                    }}
                    onClick={() => setShowToolBar(true)}
                  >
                    <MoreHoriz sx={{ fontSize: 30 }} />
                  </IconButton>
                </Box>
              )}
            </AnimatePresence>
          )}
        </Stack>
      </>
    );
  };

  if (fullScreen) {
    return (
      <CustomDialog
        open
        fullWidth
        allowBackdropClick
        maxWidth="lg"
        onClose={() => {
          setFullScreen(false);
        }}
      >
        {renderBody(true)}
      </CustomDialog>
    );
  }

  return (
    <>
      {renderBody()}
      {(chart.displayOptions?.fullAttributesList || []).length > 0 && (
        <AnalyticsAttributionSidebar
          open={filterOpen}
          setOpen={setFFilterOpen}
          chart={parsedChart}
          setChart={setParsedChart}
          dimensions={chart.displayOptions!.fullAttributesList!}
        />
      )}
    </>
  );
}

export default memo(AnalyticsCard);
