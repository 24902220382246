import {
  dateFormat,
  dateFormatExtended,
  dateTimeFormat,
  monthFormat,
  timeFormat,
} from "cfg";
import moment, { Moment } from "moment";

export const dateRange = (
  dates: string[],
  range?: "hour" | "day" | "week" | "month"
) => {
  const dateArray: string[] = [];
  dates.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];

  let currentDate = moment(startDate);

  while (currentDate <= moment(endDate)) {
    dateArray.push(moment.utc(currentDate).format());
    // Use UTC date to prevent problems with time zones and DST
    currentDate.add(1, range || "day");
  }

  return dateArray;
};

export const getDatesBetween = (
  start: Moment | Date,
  end: Moment | Date,
  granuality?: "hours" | "days" | "weeks" | "months"
): Moment[] => {
  const dates: Moment[] = [];
  let currentDate = moment(start);

  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.clone()); // Clone to avoid mutating the original moment
    currentDate.add(1, granuality || "days"); // Increment by one day
  }

  return dates;
};

export const secondsToMinSecondsText = (s: number): string => {
  if (s < 60) {
    return Math.floor(s) + (s === 1 ? " sec" : " secs");
  }

  const mins = Math.floor(s / 60);
  const remainingSecs = Math.floor(s % 60);
  const formattedMins = mins + (mins === 1 ? " min" : " mins");
  const formattedSecs =
    remainingSecs + (remainingSecs === 1 ? " sec" : " secs");

  if (mins === 0) {
    return formattedSecs;
  } else if (remainingSecs === 0) {
    return formattedMins;
  } else {
    return `${formattedMins} ${formattedSecs}`;
  }
};
export const secondsToHrMinText = (s: number): string => {
  let mins = s / 60;
  let h = mins / 60;
  let m = Math.round(mins % 60);
  h = Math.floor(h);
  h = h < 10 ? 0 + h : h;
  m = m < 10 ? 0 + m : m;
  return `${h.toLocaleString()} hrs ${m} mins`;
};

export const hoursToHrMinText = (h: number): string => {
  let mins = h * 60;
  let m = Math.round(mins % 60);
  h = Math.floor(h);
  h = h < 10 ? 0 + h : h;
  m = m < 10 ? 0 + m : m;
  return `${h.toLocaleString()} hrs ${m} mins`;
};

export const daysToDayHrText = (d: number): string => {
  let hours = d * 24;
  let h = Math.round(hours % 24);
  d = Math.floor(d);
  d = d < 10 ? 0 + d : d;
  h = h < 10 ? 0 + h : h;
  return `${d.toLocaleString()} days ${h} hrs`;
};

export const friendlyDateTime = (
  date?: string | moment.Moment | Date,
  loc?: string
): string =>
  loc
    ? moment(date).clone().tz(loc).format(dateTimeFormat)
    : moment(date).clone().utc().format(dateTimeFormat);

// removes time from date so timezone is no effect, has to handle multiple date formats
export const friendlyDate = (date?: string | moment.Moment | Date): string => {
  const parsedDate = getParsedDateNoTime(date).startOf("day");
  return parsedDate.isValid() ? parsedDate.format(dateFormat) : "";
};

export const friendlyDateExtended = (
  date?: string | moment.Moment | Date
): string => {
  const parsedDate = getParsedDateNoTime(date).startOf("day");
  return parsedDate.isValid() ? parsedDate.format(dateFormatExtended) : "";
};

export const friendlyMonth = (date?: string | moment.Moment | Date): string => {
  const parsedDate = getParsedDateNoTime(date).startOf("day");
  return parsedDate.isValid() ? parsedDate.format(monthFormat) : "";
};

export const friendlyTime = (date?: string | moment.Moment | Date): string =>
  moment(date).format(timeFormat);

const getParsedDateNoTime = (date?: string | moment.Moment | Date) => {
  const formats = [
    moment.ISO_8601,
    "YYYY-MM-DD", // Common date format
    "DD MMM YYYY", // Day Month Year format
    "MM/DD/YYYY", // US format
    "YYYY/MM/DD", // Year Month Day format
  ];

  let parsedDate;
  if (typeof date === "string") {
    for (let format of formats) {
      parsedDate = moment(date, format, true); // Strict parsing so will fail if not right
      if (parsedDate.isValid()) {
        break;
      }
    }
  } else {
    parsedDate = moment(date);
  }

  return parsedDate;
};

export const getPeriodComparison = (
  comparisonWindowDays: number,
  from: moment.Moment | null,
  to: moment.Moment | null
) => {
  return {
    from: (from || moment())
      .clone()
      .subtract(from ? comparisonWindowDays : comparisonWindowDays * 2, "days")
      .utc()
      .startOf("day")
      .toDate(),
    to: (to || moment())
      .clone()
      .subtract(comparisonWindowDays, "days")
      .utc()
      .endOf("day")
      .toDate(),
  };
};

export const getYearComparison = (
  from: moment.Moment | null,
  to: moment.Moment | null
) => ({
  from: (from || moment())
    .clone()
    .subtract(1, "year")
    .utc()
    .startOf("day")
    .toDate(),
  to: (to || moment()).clone().subtract(1, "year").utc().endOf("day").toDate(),
});

export const getCustomComparison = (
  comparisonWindowDays: number,
  from: moment.Moment | null
) => {
  const customFrom = (from || moment())
    .clone()
    .subtract(from ? 0 : comparisonWindowDays, "days")
    .utc()
    .startOf("day");

  const customTo = customFrom
    .clone()
    .add(comparisonWindowDays, "days")
    .utc()
    .endOf("day");

  return {
    from: customFrom.toDate(),
    to: customTo.toDate(),
  };
};
