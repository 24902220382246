import {
  Alert,
  alpha,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  DateCalendar,
  DateField,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import {
  friendlyDateTime,
  getCustomComparison,
  getPeriodComparison,
  getYearComparison,
} from "Helpers/dates";
import { CallbackOrVal, GenericDialoagProps } from "Interfaces";
import moment from "moment";
import { TComparisonMode } from "Providers/FromToProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

interface CustomPickerDayProps extends PickersDayProps<moment.Moment> {
  isFrom: boolean;
  isTo: boolean;
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, isFrom, isTo }) => {
  return {
    borderRadius: 0,
    "&:focus": {
      backgroundColor: "transparent",
    },
    ...(isHovered && {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.2)}!important`,
    }),
    ...(isSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isFrom && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isTo && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  };
}) as React.ComponentType<CustomPickerDayProps>;

interface IDayProps extends PickersDayProps<moment.Moment> {
  from?: moment.Moment | null;
  to?: moment.Moment | null;
  hoveredDay?: moment.Moment | null;
}

function Day(props: IDayProps) {
  const { day, from, to, hoveredDay, ...rest } = props;

  const isSelected = useMemo(() => {
    if (!to && !from) {
      return false;
    }
    if (from && !to) {
      return day.startOf("day").isSame(from.startOf("day"));
    }
    return day.isSameOrAfter(from) && (!to || day.isSameOrBefore(to));
  }, [day, to, from]);

  const isHovered = useMemo(() => {
    if (!to && !from) {
      return false;
    }
    if (from && hoveredDay && !to) {
      return (
        day.isSameOrAfter(from) &&
        day.startOf("day").isSameOrBefore(hoveredDay.startOf("day"))
      );
    }
    return false;
  }, [hoveredDay, to, from, day]);

  return (
    <CustomPickersDay
      {...rest}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isSelected}
      isHovered={isHovered}
      isFrom={!!from && day.startOf("day").isSame(from.startOf("day"))}
      isTo={
        to
          ? day.startOf("day").isSame(to.startOf("day"))
          : hoveredDay
          ? day.startOf("day").isSame(hoveredDay.startOf("day"))
          : false
      }
    />
  );
}

interface IAdvancedDatePickerProps extends GenericDialoagProps {
  onSubmit: () => void;
  from: moment.Moment | null;
  setFrom: (v: CallbackOrVal<moment.Moment | null>) => void;
  to: moment.Moment | null;
  setTo: (v: CallbackOrVal<moment.Moment | null>) => void;
  comparison: boolean;
  setComparison: (v: CallbackOrVal<boolean>) => void;
  comparisonMode: TComparisonMode;
  setComparisonMode: (v: CallbackOrVal<TComparisonMode>) => void;
  cFrom: moment.Moment | null;
  setCFrom: (v: CallbackOrVal<moment.Moment | null>) => void;
  lastNDays: number | null;
  setLastNDays: (v: CallbackOrVal<number | null>) => void;
}

function AdvancedDatePicker({
  open,
  setOpen,
  onSubmit,
  from,
  to,
  setFrom,
  setTo,
  comparison,
  setComparison,
  comparisonMode,
  setComparisonMode,
  cFrom,
  setCFrom,
  lastNDays,
  setLastNDays,
}: IAdvancedDatePickerProps) {
  const [selectedMonth, setSelectedMonth] = useState(
    from || moment().utc().subtract(1, "month")
  );

  const [hoveredDay, setHoveredDay] = useState<moment.Moment | null>(null);
  const [selectedMonthTo, setSelectedMonthTo] = useState(
    selectedMonth.clone().add(1, "month")
  );
  const onChange = useCallback(
    (date: moment.Moment | null, isTo: boolean) => {
      if (date) {
        setSelectedMonth(isTo ? date.clone().subtract(1, "month") : date);
      }

      if (!from) {
        setFrom(date);
        return;
      }
      if (!to) {
        setTo(date);
        return;
      }
      setFrom(date);
      setTo(null);
    },
    [from, setFrom, to, setTo]
  );

  useEffect(() => {
    setSelectedMonthTo(selectedMonth.clone().add(1, "month"));
  }, [selectedMonth]);

  useEffect(() => {
    if (!open) {
      setSelectedMonth(from || moment().utc().subtract(1, "month"));
    }
  }, [open, from]);

  useUpdateEffect(() => {
    if (!open || (!from && !to)) {
      return;
    }

    setLastNDays(null);
  }, [from, to, open]);

  const comparisonFromTo = useMemo(() => {
    let diff = lastNDays;
    if (!diff) {
      const duration = moment.duration(moment(to).diff(from));
      diff = Math.round(duration.asDays());
    }

    let comp = {
      from: new Date(),
      to: new Date(),
    };

    switch (comparisonMode) {
      case "period":
        comp = getPeriodComparison(diff, from, to);
        break;
      case "year":
        comp = getYearComparison(from, to);
        break;
      case "custom":
        comp = getCustomComparison(diff, cFrom);
        break;
    }

    return comp;
  }, [from, to, cFrom, comparisonMode, lastNDays]);

  return (
    <CustomDialog
      maxWidth="md"
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        sx: {
          width: 686,
        },
      }}
    >
      <DialogTitle>Choose Date Range</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0!important" }}>
        <Grid container mt={lastNDays ? 0 : 2}>
          {lastNDays && (
            <Grid item xs={12} mb={2}>
              <Typography variant="subtitle2">
                Currently selected: Last {lastNDays} days
              </Typography>
              <Typography variant="body2">
                Select a date to choose custom date range.
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Stack spacing={1}>
              <Box pr={5}>
                <DateField
                  fullWidth
                  size="small"
                  label="From"
                  value={from}
                  onChange={(d) => setFrom(d)}
                />
              </Box>
              <DateCalendar
                views={["day"]}
                value={selectedMonth}
                minDate={moment().utc().subtract(5, "years")}
                disableFuture
                onChange={(v) => onChange(v, false)}
                onMonthChange={(d) => setSelectedMonth(d)}
                slots={{ day: Day }}
                slotProps={{
                  day: (state) => {
                    return {
                      from,
                      to,
                      hoveredDay,
                      onPointerEnter: () => setHoveredDay(state.day),
                      onPointerLeave: () => setHoveredDay(null),
                    } as any;
                  },
                }}
                sx={{
                  position: "relative",
                  "& .MuiPickersCalendarHeader-root": {
                    paddingLeft: 0,
                    flexDirection: "row-reverse",
                    "& .MuiPickersCalendarHeader-labelContainer": {
                      margin: "auto",
                      paddingRight: "42px",
                    },
                  },
                  "& .MuiDayCalendar-header, & .MuiDayCalendar-weekContainer": {
                    justifyContent: "flex-start",
                  },
                  "& .MuiIconButton-edgeEnd": {
                    position: "absolute",
                    left: 0,
                    top: 12,
                  },
                  "& .MuiIconButton-edgeStart": {
                    display: "none",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <Box pl={5}>
                <DateField
                  fullWidth
                  size="small"
                  label="To"
                  value={to}
                  onChange={(d) => setTo(d)}
                />
              </Box>
              <DateCalendar
                views={["day"]}
                value={selectedMonthTo}
                minDate={moment().utc().subtract(5, "years")}
                onChange={(v) => onChange(v, true)}
                disableFuture
                slots={{
                  day: Day,
                }}
                slotProps={{
                  day: (state) => {
                    return {
                      from,
                      to,
                      hoveredDay,
                      onPointerEnter: () => setHoveredDay(state.day),
                      onPointerLeave: () => setHoveredDay(null),
                    } as any;
                  },
                }}
                sx={{
                  position: "relative",
                  "& .MuiPickersCalendarHeader-root": {
                    paddingLeft: 0,
                    flexDirection: "row",
                    "& .MuiPickersCalendarHeader-labelContainer": {
                      margin: "auto",
                      paddingLeft: "42px",
                    },
                  },
                  "& .MuiDayCalendar-header, & .MuiDayCalendar-weekContainer": {
                    justifyContent: "flex-end",
                  },
                  "& .MuiIconButton-edgeEnd": {
                    display: "none",
                  },
                  "& .MuiIconButton-edgeStart": {
                    position: "absolute",
                    right: 0,
                    top: 12,
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={comparison ? comparison : false}
                onChange={(e, c) => {
                  setComparison(c);
                }}
              />
            }
            label={"Compare"}
            labelPlacement="start"
          />
          {comparison && (
            <ToggleButtonGroup
              exclusive
              fullWidth
              color="primary"
              value={comparisonMode}
              onChange={(e, v: TComparisonMode) => {
                setComparisonMode(v);
              }}
            >
              <ToggleButton value={"period"}>Previous Period</ToggleButton>
              <ToggleButton value={"year"}>Previous Year</ToggleButton>
              <ToggleButton value={"custom"}>Custom</ToggleButton>
            </ToggleButtonGroup>
          )}
        </Stack>
        {comparison && (
          <Box mt={2}>
            <Alert severity="info">
              <Typography variant="subtitle2">Comparison Range</Typography>
              <Typography>
                {friendlyDateTime(comparisonFromTo.from)} -
                {friendlyDateTime(comparisonFromTo.to)}
              </Typography>
            </Alert>
          </Box>
        )}
        {comparison && comparisonMode === "custom" && (
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" mt={2.5}>
                Select the start date of your comparison range
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <DateCalendar
                views={["day"]}
                value={cFrom}
                minDate={moment().utc().subtract(5, "years")}
                disableFuture
                onChange={(v) => setCFrom(v)}
                sx={{
                  "& .MuiPickersCalendarHeader-root": {
                    paddingLeft: 1,
                  },
                  "& .MuiDayCalendar-header, & .MuiDayCalendar-weekContainer": {
                    justifyContent: "flex-start",
                  },
                  "& .MuiPickersArrowSwitcher-root": {
                    transform: "translateX(-24px)",
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>

          <Button
            onClick={onSubmit}
            color="primary"
            disabled={!lastNDays && (!from || !to)}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </CustomDialog>
  );
}

export default AdvancedDatePicker;
