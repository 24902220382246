import { ApiDimension, ApiProjectSettingKey } from "@incendium/api";
import { Portal, Stack, Switch, Typography } from "@mui/material";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { PageSubAction } from "consts";
import CampaignAnalysisExplorer from "../components/CampaignAnalysisExplorer";
import { motion } from "framer-motion";
import { useSelectedProject } from "Hooks";
import { useMemo, useState } from "react";
import useAppendSearchState from "Hooks/useAppendSearchState";

function CampaignAnalysis() {
  const [exploreMode, setExploreMode] = useAppendSearchState("ca-m", true);
  const [termDimension, setTermDimension] = useState<
    ApiDimension.DIMENSION_TERM | ApiDimension.DIMENSION_TERM_WITH_MATCH_TYPE
  >(ApiDimension.DIMENSION_TERM);
  const { projectSettings } = useSelectedProject();

  const hasMatchType = useMemo(() => {
    if (!projectSettings) {
      return false;
    }
    return (
      projectSettings[ApiProjectSettingKey.BING_ENABLE_MATCH_TYPE] === "true" ||
      projectSettings[ApiProjectSettingKey.GOOGLE_ENABLE_MATCH_TYPE] === "true"
    );
  }, [projectSettings]);
  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <Stack
          component={motion.div}
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 1 }}
          direction={"row"}
          spacing={2}
        >
          {hasMatchType && (
            <StyledFlexBox>
              <Stack direction={"row"} alignItems="center" mr={4}>
                <Typography color={"secondary"}>Term</Typography>
                <Switch
                  checked={
                    termDimension ===
                    ApiDimension.DIMENSION_TERM_WITH_MATCH_TYPE
                  }
                  onChange={(e, checked) =>
                    setTermDimension(
                      checked
                        ? ApiDimension.DIMENSION_TERM_WITH_MATCH_TYPE
                        : ApiDimension.DIMENSION_TERM
                    )
                  }
                />
                <Typography color={"primary"}>Term With Matchtype</Typography>
              </Stack>
              <ClickableTooltip
                placement="bottom"
                width={335}
                text={
                  <>
                    <Typography variant="body2" color={"inherit"} mb={1}>
                      <strong>Select Term With Matchtype</strong> to append
                      matchtype to you terms, <i>note</i> only works for
                      providers where you have turned on this feature in the
                      integration config.
                    </Typography>
                  </>
                }
                icon={<StyledHelp />}
              />
            </StyledFlexBox>
          )}
          <StyledFlexBox>
            <Stack direction={"row"} alignItems="center" mr={4}>
              <Typography color={"secondary"}>Analyze</Typography>
              <Switch
                checked={Boolean(exploreMode)}
                onChange={(e, checked) => setExploreMode(checked)}
              />
              <Typography color={"primary"}>Explore</Typography>
            </Stack>
            <ClickableTooltip
              placement="bottom"
              width={335}
              text={
                <>
                  <Typography variant="body2" color={"inherit"} mb={1}>
                    <strong>Analyze</strong>: Gain an overview with a standard
                    table showcasing the full picture of your campaigns.
                  </Typography>
                  <Typography variant="body2" color={"inherit"}>
                    <strong>Explore</strong>: Dive deeper by drilling down into
                    your campaigns. (Handy tip: Hold Shift while selecting
                    options to choose multiple items.)"
                  </Typography>
                </>
              }
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Stack>
      </Portal>
      <CampaignAnalysisExplorer
        mode={exploreMode ? "explore" : "analyze"}
        termDimension={termDimension}
      />
    </>
  );
}

export default CampaignAnalysis;
