import { ApiDimension } from "@incendium/api";
import {
  alpha,
  styled,
  TableCell,
  TableRow,
  TableRowProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDimension } from "features/analytics/services/dimensionsFormatter";
import { HTMLMotionProps, motion } from "framer-motion";
import { TFramerAnimationProps } from "Interfaces";
import { useCallback, useState } from "react";

interface IStyledTableRowProps
  extends TableRowProps,
    Pick<HTMLMotionProps<any>, TFramerAnimationProps> {
  hovered?: boolean;
  actionHovered?: boolean;
  active?: boolean;
}
const StyledTableRow = styled(TableRow)<IStyledTableRowProps>(
  ({ theme, hovered, actionHovered, active }) => ({
    height: 56,
    background: active
      ? theme.palette.secondary.main
      : actionHovered
      ? alpha(theme.palette.primary.main, 0.8)
      : hovered
      ? alpha(theme.palette.secondary.main, 0.8)
      : undefined,
    "& .MuiTableCell-root": {
      color: hovered ? "white" : undefined,
    },
  })
);
const StyledTableCell = styled(TableCell)<{
  clickable?: boolean;
  active?: boolean;
}>(({ theme, clickable, active }) => ({
  cursor: clickable ? "pointer" : undefined,
  color: active ? " white" : theme.palette.secondary.main,
}));

interface ICampaignAnalysisTableRowProps {
  current: boolean;
  data: any;
  firstCols: { key: ApiDimension; text: string }[];
  onClick?: (v: string) => void;
  selecteValues: string[];
  dimension: ApiDimension;
}

function CampaignAnalysisTableRow({
  current,
  data,
  firstCols,
  onClick,
  selecteValues,
  dimension,
}: ICampaignAnalysisTableRowProps) {
  const [hovered, setHovered] = useState(false);

  const dimensionText = useCallback(
    (dimension: ApiDimension) => {
      return data[dimension];
    },
    [data]
  );

  return (
    <StyledTableRow
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={`${dimensionText(firstCols[0].key)}`}
      hovered={hovered}
      active={selecteValues.includes(`${dimensionText(firstCols[0].key)}`)}
    >
      {firstCols.map((firstCol) => (
        <Tooltip
          arrow
          key={firstCol.key}
          title={formatDimension(dimension, `${dimensionText(firstCol.key)}`)}
        >
          <StyledTableCell
            onClick={() => onClick && onClick(`${dimensionText(firstCol.key)}`)}
            clickable={typeof onClick !== "undefined"}
            active={selecteValues.includes(`${dimensionText(firstCol.key)}`)}
            onMouseOver={() => onClick && setHovered(true)}
            onMouseLeave={() => onClick && setHovered(false)}
          >
            <Typography
              variant="body2"
              fontWeight={"bold"}
              color="inherit"
              noWrap
            >
              {formatDimension(dimension, `${dimensionText(firstCol.key)}`)}
            </Typography>
          </StyledTableCell>
        </Tooltip>
      ))}
    </StyledTableRow>
  );
}

export default CampaignAnalysisTableRow;
