import { Box, Grid, Link, Typography } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { ApiAPITokenType } from "@incendium/api";
import APITokenCard from "../components/APITokenCard";
import Woo from "Assets/Images/ThirdParty/woocommerce-logo-color-black@2x.png";
import Shopify from "Assets/Images/ThirdParty/shopify_logo_black.png";
import Stripe from "Assets/Images/ThirdParty/stripe-small.png";
import Loading from "Components/Loading/Loading";

function WebhooksList() {
  const { selectedClient, selectedProject } = useSelectedProject();

  if (!selectedClient?.id || !selectedProject?.id) {
    return (
      <Box display={"flex"} my={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <APITokenCard
          title="Woocommerce"
          type={ApiAPITokenType.API_TOKEN_TYPE_WOOCOMMERCE}
          clientId={selectedClient.id}
          projectId={selectedProject.id}
          projectKey={selectedProject.key || ""}
          logo={Woo}
          info={
            <Box>
              <Typography variant="body2" color={"text.secondary"}>
                Selecting this will produce a auth key, use this key as webhook
                secret
              </Typography>
              <Typography variant="body2" color={"text.secondary"}>
                For More infomation on setting up Woocemmerce Webhooks please
                visit{" "}
                <Link
                  href={"https://woocommerce.com/document/webhooks/"}
                  target="_blank"
                >
                  https://woocommerce.com/document/webhooks/
                </Link>
              </Typography>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={4}>
        <APITokenCard
          title="Shopify"
          type={ApiAPITokenType.API_TOKEN_TYPE_SHOPIFY}
          clientId={selectedClient.id}
          projectId={selectedProject.id}
          projectKey={selectedProject.key || ""}
          logo={Shopify}
          imageSx={{ maxHeight: 35 }}
          info={
            <Box>
              <Typography variant="body2" color={"text.secondary"}>
                Selecting this will produce a auth key, use this key that you
                use when installing the incendium shopify app
              </Typography>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={4}>
        <APITokenCard
          title="Stripe"
          type={ApiAPITokenType.API_TOKEN_TYPE_STRIPE}
          clientId={selectedClient.id}
          projectId={selectedProject.id}
          projectKey={selectedProject.key || ""}
          logo={Stripe}
          imageSx={{ transform: `translateX(-10px)` }}
          info={
            <Box>
              <Typography variant="body2" color={"text.secondary"}>
                Selecting this will produce URL which you can use in Stripe. We
                recomend you use either the payment_intent.succeeded for 1 of
                payments or the invoice.paid event for subscriptions.
              </Typography>
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
}

export default WebhooksList;
