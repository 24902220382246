import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import { Box, Grid, GridSize, Typography } from "@mui/material";
import { ColsCol, ColsLayout } from "Components/ColsLayout/ColsLayout";
import { Glass } from "Components/Glass";
import StyledDrawer, {
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import TrafficTable from "features/analytics/components/TrafficTable";
import SalesTable from "features/analytics/components/SalesTable";
import { table4Rows } from "features/analytics/constants/constants";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import CampaignAnalysisAnalyzeFilters from "../components/CampaignAnalysisAnalyzeFilters";
import CampaignAnalysisTable from "../components/CampaignAnalysisTable";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { useKeyPress, useUpdateEffect } from "react-use";
import useAppendSearchState from "Hooks/useAppendSearchState";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";

interface ICampaignAnalysisExplorerProps {
  mode: "explore" | "analyze";
  termDimension:
    | ApiDimension.DIMENSION_TERM
    | ApiDimension.DIMENSION_TERM_WITH_MATCH_TYPE;
  paidChannelAttributes: ApiChartAttribute[]; // from hoc
}

function CampaignAnalysisExplorer({
  mode,
  termDimension,
  paidChannelAttributes,
}: ICampaignAnalysisExplorerProps) {
  const [colWidths, setColsWidth] = useState<GridSize[]>([9, 1, 1, 1]);
  const [selectedPlatforms, setSelectedPlatforms] = useAppendSearchState<
    string[]
  >("ca-p", []);
  const [selectedCampaigns, setSelectedCampaigns] = useAppendSearchState<
    string[]
  >("ca-c", []);
  const [selectedGroups, setSelectedGroups] = useAppendSearchState<string[]>(
    "ca-g",
    []
  );
  const [selectedKeywords, setSelectedKeywords] = useAppendSearchState<
    string[]
  >("ca-k", []);
  const [step, setStep] = useAppendSearchState("ca-s", 0);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [detailsDrawerAttributes, setDetailsDrawerAttributes] = useState<
    ApiChartAttribute[]
  >([]);
  const [detailsDrawerMetric, setDetailsDrawerMetic] = useState<ApiMetric>(
    ApiMetric.METRIC_NOT_SET
  );
  const [isShiftPressed] = useKeyPress("Shift");
  const [conversionMetric, setConversionMetric] = useState<AttributionMetric>(
    AttributionMetric.MACRO
  );
  const [attributionModel, setAttributionModel] = useState<AttributionType>(
    AttributionType.FIRST
  );
  const [publisherConversion, setPublisherConversion] = useState<ApiMetric>(
    ApiMetric.METRIC_PUBLISHER_CONVERSIONS
  );

  useUpdateEffect(() => {
    if (mode === "analyze") {
      setStep(0);
    }
    setSelectedPlatforms([]);
    setSelectedCampaigns([]);
    setSelectedGroups([]);
    setSelectedKeywords([]);
  }, [mode]);

  const setSelected = useCallback(
    (state: string[], val: string) =>
      produce(state, (draft) => {
        if (isShiftPressed) {
          const idx = draft.findIndex((d) => d === val);
          if (idx >= 0) {
            draft.splice(idx, 1);
          } else {
            draft.push(val);
          }
        } else {
          draft.length = 0;
          draft.push(val);
        }
      }),
    [isShiftPressed]
  );

  const selected = useCallback(
    (initailStep: number) => {
      switch (initailStep) {
        case 1:
          return selectedCampaigns;
        case 2:
          return selectedGroups;
        case 3:
          return selectedKeywords;
        default:
          return selectedPlatforms;
      }
    },
    [selectedPlatforms, selectedCampaigns, selectedGroups, selectedKeywords]
  );

  const onFirstColSelect = useCallback(
    (toStep: number) => (val) => {
      const sel = selected(toStep - 1);
      const idx = (sel || []).findIndex((s) => s === val);
      let step = toStep;

      if (
        (isShiftPressed && idx >= 0 && (sel || []).length <= 1) ||
        (!isShiftPressed && idx >= 0)
      ) {
        step = toStep - 1;
      }

      setStep(step);

      switch (step) {
        case 1:
          if (toStep === step) {
            setSelectedPlatforms((s) => setSelected(s || [], val));
          }
          break;
        case 2:
          if (toStep === step) {
            setSelectedCampaigns((s) => setSelected(s || [], val));
          }
          break;
        case 3:
          if (toStep === step) {
            setSelectedGroups((s) => setSelected(s || [], val));
          }
          break;
        case 4:
          if (toStep === step) {
            setSelectedKeywords((s) => setSelected(s || [], val));
          }
          break;

        default:
          break;
      }
    },
    [isShiftPressed, setSelected, selected]
  );

  useEffect(() => {
    switch (step) {
      case 0:
        setColsWidth([9, 1, 1, 1]);
        setSelectedPlatforms([]);
        setSelectedCampaigns([]);
        setSelectedGroups([]);
        setSelectedKeywords([]);
        break;
      case 1:
        setColsWidth([1, 9, 1, 1]);
        setSelectedCampaigns([]);
        setSelectedGroups([]);
        setSelectedKeywords([]);
        break;
      case 2:
        setColsWidth([1, 1, 9, 1]);
        setSelectedGroups([]);
        setSelectedKeywords([]);
        break;
      case 3:
        setColsWidth([1, 1, 1, 9]);
        setSelectedKeywords([]);
        break;

      default:
        break;
    }
  }, [
    step,
    setSelectedCampaigns,
    setSelectedGroups,
    setSelectedKeywords,
    setSelectedPlatforms,
  ]);

  const onMetricClick = useCallback(
    (metric: ApiMetric, dimension: ApiDimension, dimensionValue: string) => {
      setDetailsDrawerMetic(metric);
      setDetailsDrawerAttributes((attr) =>
        produce(attr, (draft) => {
          draft.length = 0;
          draft.push(...paidChannelAttributes);

          if (dimension === ApiDimension.DIMENSION_SOURCE) {
            draft.push({
              key: ApiDimension.DIMENSION_SOURCE,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedPlatforms || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_SOURCE,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }

          if (dimension === ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN) {
            draft.push({
              key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedCampaigns || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }

          if (dimension === ApiDimension.DIMENSION_CONTENT) {
            draft.push({
              key: ApiDimension.DIMENSION_CONTENT,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedGroups || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_CONTENT,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }
          if (dimension === termDimension) {
            draft.push({
              key: termDimension,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedKeywords || []).forEach((s) => {
              draft.push({
                key: termDimension,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }
        })
      );
      setOpenDetailsDrawer(true);
    },
    [
      selectedPlatforms,
      selectedCampaigns,
      selectedGroups,
      selectedKeywords,
      paidChannelAttributes,
      termDimension,
    ]
  );

  useEffect(() => {
    if (!openDetailsDrawer) {
      setDetailsDrawerAttributes([]);
    }
  }, [openDetailsDrawer]);

  return (
    <>
      {mode === "analyze" && (
        <Grid container spacing={2} sx={{ minHeight: table4Rows }} mb={2}>
          <Grid item xs={8}>
            <AnalyticsCard
              chart={{
                name: "Publisher Trend",
                template: ApiChartTemplate.TREND,
                type: ApiChartType.GRAPH,
                dimension: [
                  ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                  ApiDimension.DIMENSION_SOURCE,
                ],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PUBLISHER_CLICKS],
                  },
                ],
                attributes: paidChannelAttributes,
                displayOptions: {
                  noFilters: true,
                  availableMetrics: [
                    ApiMetric.METRIC_PUBLISHER_CLICKS,
                    ApiMetric.METRIC_PUBLISHER_IMPRESSIONS,
                    ApiMetric.METRIC_PUBLISHER_CTR,
                    ApiMetric.METRIC_PUBLISHER_CPC,
                    ApiMetric.METRIC_SPEND,
                  ],
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <AnalyticsCard
              chart={{
                name: "CPC By Publisher",
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.PIE,
                dimension: [ApiDimension.DIMENSION_SOURCE],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PUBLISHER_CPC],
                  },
                ],
                attributes: paidChannelAttributes,
                displayOptions: {
                  noFilters: true,
                  showTotals: true,
                },
              }}
            />
          </Grid>
        </Grid>
      )}
      <Glass>
        {mode === "analyze" ? (
          <>
            <Box py={2}>
              <CampaignAnalysisAnalyzeFilters
                selectedPlatforms={selectedPlatforms || []}
                setSelectedPlatforms={setSelectedPlatforms}
                selectedCampaigns={selectedCampaigns || []}
                setSelectedCampaigns={setSelectedCampaigns}
                selectedGroups={selectedGroups || []}
                setSelectedGroups={setSelectedGroups}
                selectedKeywords={selectedKeywords || []}
                setSelectedKeywords={setSelectedKeywords}
              />
            </Box>
            <CampaignAnalysisTable
              mode={mode}
              firstCols={[
                {
                  key: ApiDimension.DIMENSION_SOURCE,
                  text: "Platforms",
                },
                {
                  key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                  text: "Campaigns",
                },
                {
                  key: ApiDimension.DIMENSION_CONTENT,
                  text: "Sets",
                },
                {
                  key: termDimension,
                  text: "Keywords",
                },
              ]}
              selecteValues={selectedPlatforms}
              headerComponent={
                <CampaignAnalysisAnalyzeFilters
                  selectedPlatforms={selectedPlatforms || []}
                  setSelectedPlatforms={setSelectedPlatforms}
                  selectedCampaigns={selectedCampaigns || []}
                  setSelectedCampaigns={setSelectedCampaigns}
                  selectedGroups={selectedGroups || []}
                  setSelectedGroups={setSelectedGroups}
                  selectedKeywords={selectedKeywords || []}
                  setSelectedKeywords={setSelectedKeywords}
                />
              }
              conversionMetric={conversionMetric}
              setConversionMetric={setConversionMetric}
              attributionModel={attributionModel}
              setAttributionModel={setAttributionModel}
              publisherConversion={publisherConversion}
              setPublisherConversion={setPublisherConversion}
              filters={[
                ...(selectedPlatforms || []).map((sp) => ({
                  key: ApiDimension.DIMENSION_SOURCE,
                  operator: ApiOperator.CONTAINS,
                  value: sp,
                })),
                ...(selectedCampaigns || []).map((sc) => ({
                  key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                  operator: ApiOperator.CONTAINS,
                  value: sc,
                })),
                ...(selectedGroups || []).map((sg) => ({
                  key: ApiDimension.DIMENSION_CONTENT,
                  operator: ApiOperator.CONTAINS,
                  value: sg,
                })),
                ...(selectedKeywords || []).map((sg) => ({
                  key: termDimension,
                  operator: ApiOperator.CONTAINS,
                  value: sg,
                })),
              ]}
              currentStep={step}
              step={0}
            />
          </>
        ) : (
          <ColsLayout>
            <ColsCol width={colWidths[0]}>
              <CampaignAnalysisTable
                mode={mode}
                title="Publishers"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_SOURCE,
                    text: "Platforms",
                  },
                ]}
                onClick={(val) => onFirstColSelect(1)(val)}
                selecteValues={selectedPlatforms}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[]}
                currentStep={step}
                step={0}
                onTitleClick={() => setStep(0)}
                onMetricClick={onMetricClick}
                showProviderIcon
              />
            </ColsCol>
            <ColsCol width={colWidths[1]}>
              <CampaignAnalysisTable
                mode={mode}
                title="Campaigns"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                    text: "Campaigns",
                  },
                ]}
                onClick={(val) => onFirstColSelect(2)(val)}
                selecteValues={selectedCampaigns}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[
                  ...(selectedPlatforms || []).map((sp) => ({
                    key: ApiDimension.DIMENSION_SOURCE,
                    value: sp,
                  })),
                ]}
                currentStep={step}
                step={1}
                onTitleClick={() => setStep(1)}
                onMetricClick={onMetricClick}
              />
            </ColsCol>
            <ColsCol width={colWidths[2]}>
              <CampaignAnalysisTable
                mode={mode}
                title="Content"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_CONTENT,
                    text: "Content",
                  },
                ]}
                onClick={(val) => onFirstColSelect(3)(val)}
                selecteValues={selectedGroups}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[
                  ...(selectedPlatforms || []).map((sp) => ({
                    key: ApiDimension.DIMENSION_SOURCE,
                    value: sp,
                  })),
                  ...(selectedCampaigns || []).map((sc) => ({
                    key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                    value: sc,
                  })),
                ]}
                currentStep={step}
                step={2}
                onTitleClick={() => setStep(2)}
                onMetricClick={onMetricClick}
              />
            </ColsCol>
            <ColsCol width={colWidths[3]}>
              <CampaignAnalysisTable
                mode={mode}
                title="Terms"
                firstCols={[
                  {
                    key: termDimension,
                    text: "Terms",
                  },
                ]}
                selecteValues={selectedKeywords}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[
                  ...(selectedPlatforms || []).map((sp) => ({
                    key: ApiDimension.DIMENSION_SOURCE,
                    value: sp,
                  })),
                  ...(selectedCampaigns || []).map((sc) => ({
                    key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                    value: sc,
                  })),
                  ...(selectedGroups || []).map((sg) => ({
                    key: ApiDimension.DIMENSION_CONTENT,
                    value: sg,
                  })),
                ]}
                currentStep={step}
                step={3}
                onTitleClick={() => setStep(3)}
                onMetricClick={onMetricClick}
              />
            </ColsCol>
          </ColsLayout>
        )}
      </Glass>
      <StyledDrawer
        anchor="right"
        open={openDetailsDrawer}
        onClose={() => setOpenDetailsDrawer(false)}
        hideDivider
      >
        <StyledDrawerTitle>
          {detailsDrawerMetric === ApiMetric.METRIC_SESSIONS_COUNT ? (
            <>
              <Typography variant="subtitle1">Explore Traffic</Typography>
              <Typography variant="body2">
                Explore Traffic from Paid Platforms for your selected Provider /
                Campaign etc
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">Explore Sales</Typography>
              <Typography variant="body2">
                Explore Sales from Paid Platforms for your selected Provider /
                Campaign etc
              </Typography>
            </>
          )}
        </StyledDrawerTitle>
        <StyledDrawerContainer sx={{ minWidth: "85vw" }}>
          {detailsDrawerMetric === ApiMetric.METRIC_SESSIONS_COUNT ? (
            <TrafficTable attributes={detailsDrawerAttributes} />
          ) : (
            <SalesTable attributes={detailsDrawerAttributes} />
          )}
        </StyledDrawerContainer>
      </StyledDrawer>
    </>
  );
}

export default withPaidChannelAttributes(CampaignAnalysisExplorer);
