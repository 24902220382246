import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import Image from "mui-image";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Check, Delete, Settings } from "@mui/icons-material";
import { useAPITokens } from "Hooks/useAPITokens";
import { ApiAPITokenType, ApiAPITokenValueType } from "@incendium/api";
import { useSave } from "Hooks/useSave";
import produce from "immer";
import { motion } from "framer-motion";
import APITokenDrawer from "features/dataConnectors/components/APITokenDrawer";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import {
  createAPIToken,
  deleteToken,
  updateAPITokenValues,
} from "features/dataConnectors/components/apiTokenService";
import { useNotification } from "Hooks";
import ShopifyInstructions from "features/dataConnectors/components/ShopifyInstructions";
import { ApiURL } from "config";

interface IAPITokenCardProps {
  type: ApiAPITokenType;
  clientId: number;
  projectId: number;
  projectKey: string;
  logo: string;
  title: string;
  info: React.ReactChild;
  hideURL?: boolean;
  imageSx?: SxProps<Theme>;
}

function APITokenCard({
  type,
  clientId,
  projectId,
  projectKey,
  logo,
  title,
  info,
  imageSx,
}: IAPITokenCardProps) {
  const { tokens, setTokens } = useAPITokens();
  const { saving, save } = useSave();
  const token = useMemo(() => {
    return tokens.find((t) => t.type === type);
  }, [tokens, type]);
  const [orderPath, setOrderPath] = useState("order-recieved");
  const [openConfig, setOpenConfig] = useState(false);
  const { showSuccessNotification, showErrorNotification } = useNotification();

  useEffect(() => {
    if (!token) {
      return;
    }
    const value =
      (token.values || []).find(
        (v) =>
          v.type ===
          ApiAPITokenValueType.API_TOKEN_VALUE_TYPE_WOOCOMMERCE_ORDER_PATH
      )?.value || "order-recieved";
    setOrderPath(value);
  }, [token]);

  const newToken = useCallback(async () => {
    const res = await createAPIToken(clientId, type, [
      {
        type: ApiAPITokenValueType.API_TOKEN_VALUE_TYPE_PROJECT_ID,
        value: `${projectId as number}`,
      },
    ]);
    setTokens(
      produce(tokens, (draft) => {
        draft.push(res);
      })
    );
  }, [clientId, projectId, setTokens, tokens, type]);

  const removeToken = useCallback(
    async (id: number) => {
      await deleteToken(clientId, id);
      setTokens(
        produce(tokens, (draft) => {
          const idx = tokens.findIndex((t) => t.id === id);
          draft.splice(idx, 1);
        })
      );
    },
    [clientId, tokens, setTokens]
  );

  const handleChecked = useCallback(
    async (checked: boolean) => {
      save(() => {
        if (checked) {
          newToken();
        } else if (token) {
          removeToken(token?.id as number);
        }
      });
    },
    [newToken, removeToken, save, token]
  );

  const handleOnConfigure = useCallback(async () => {
    setOpenConfig(true);
  }, []);

  const saveOrderPath = useCallback(async () => {
    if (!token || !token?.id) {
      return;
    }
    try {
      let values = (token.values || []).map((v) => ({ ...v }));
      const orderPathIdx = (values || []).findIndex(
        (v) =>
          v.type ===
          ApiAPITokenValueType.API_TOKEN_VALUE_TYPE_WOOCOMMERCE_ORDER_PATH
      );
      if (orderPathIdx >= 0) {
        values![orderPathIdx].value = orderPath;
      } else {
        values.push({
          type: ApiAPITokenValueType.API_TOKEN_VALUE_TYPE_WOOCOMMERCE_ORDER_PATH,
          value: orderPath,
        });
      }
      const res = await updateAPITokenValues(clientId, token!.id, values);
      setTokens((tokens) =>
        produce(tokens, (draft) => {
          const idx = draft.findIndex((d) => d.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          }
        })
      );
      showSuccessNotification("Order Path Updated");
    } catch (error) {
      showErrorNotification("Internal Error, please try again");
    }
  }, [
    clientId,
    orderPath,
    token,
    showSuccessNotification,
    showErrorNotification,
    setTokens,
  ]);

  return (
    <>
      <GlassCard boxProps={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent={"space-between"}>
            <motion.div
              initial={{
                opacity: 0,
                x: -50,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              transition={{ delay: 0.6 }}
            >
              <Image
                height={40}
                src={logo}
                duration={50}
                fit={"contain"}
                objectPosition={"left"}
                sx={{ objectPosition: "left", ...imageSx }}
              />
            </motion.div>
            {!!token && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<Settings />}
                onClick={handleOnConfigure}
              >
                configure
              </Button>
            )}

            {saving && <CircularProgress size={"28px"} />}
          </Stack>
          <Divider />
          <Box sx={{ height: 76 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">{title}</Typography>

              {!!token && <Check color={"success"} fontSize={"small"} />}
            </Stack>

            {info}
          </Box>
          <Divider />
          <Box>
            {!!token ? (
              <Button
                size="small"
                color="secondary"
                startIcon={<Delete />}
                onClick={() => handleChecked(false)}
              >
                Remove Webhook
              </Button>
            ) : (
              <Button size="small" onClick={() => handleChecked(true)}>
                Generate Webhook
              </Button>
            )}
          </Box>
        </Stack>
      </GlassCard>
      <APITokenDrawer
        open={openConfig}
        onClose={() => setOpenConfig(false)}
        token={token}
      >
        {type === ApiAPITokenType.API_TOKEN_TYPE_WOOCOMMERCE && (
          <AccordianChartBuilderSidebarBlock
            title="Order Path"
            subTitle={`By default woocommerce uses the order path "order-received" if you have changed this please specify here.`}
          >
            <Stack direction={"row"} spacing={2}>
              <TextField
                label="order path"
                name="order-path"
                size="small"
                fullWidth
                value={orderPath}
                onChange={(e) => {
                  setOrderPath(e.target.value);
                }}
              />
              <Button onClick={saveOrderPath} size="small">
                Save
              </Button>
            </Stack>
          </AccordianChartBuilderSidebarBlock>
        )}
        {type === ApiAPITokenType.API_TOKEN_TYPE_SHOPIFY && token && (
          <AccordianChartBuilderSidebarBlock
            title="Setup Instructions"
            subTitle={`See instructions to Setup Shopify webhook and advanced Pixel Tracking`}
          >
            <ShopifyInstructions
              webhookUrl={token.url || ""}
              incUrl={`${ApiURL}/firefly/${projectKey}/incendium.js`}
            />
          </AccordianChartBuilderSidebarBlock>
        )}
      </APITokenDrawer>
    </>
  );
}

export default APITokenCard;
