import { ArrowDropDown, Check, OpenInNew } from "@mui/icons-material";
import {
  alpha,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AnimatePresence, useAnimate } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import {
  AnalyticsAttributionIcon,
  AnalyticsChart,
  useChartData,
  metricByAttribution,
} from "features/analytics";
import { useFromToContext } from "Providers/FromToProvider";
import { useSelectedProject } from "Hooks";
import { IChart } from "Interfaces";
import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import CampaignAnalysisTableRow from "../components/CampaignAnalysisTableRow";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";
import ProviderIcon from "features/attributionAnalytics/components/ProviderIcon";
import Loading from "Components/Loading/Loading";

const StyledListTitle = styled(ListItemText)();
StyledListTitle.defaultProps = {
  primaryTypographyProps: {
    fontSize: 14,
    fontWeight: 500,
  },
};

const StyledPopper = styled(Popper)(() => ({
  zIndex: 2,
}));
StyledPopper.defaultProps = {
  placement: "bottom-end",
};

const StyledTopTableCell = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  height: 56,
  "&:hover": {
    background: alpha(theme.palette.secondary.main, 0.8),
    color: "white",
  },
}));

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  "& svg:not(.MuiSvgIcon-root)": {
    width: 40,
    fill: theme.palette.primary.dark,
  },
  "& .MuiListItemText-root": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

interface ICampaignAnalysisTableProps {
  title?: string;
  firstCols: { key: ApiDimension; text: string }[];
  onClick?: (v: string) => void;
  selecteValues: string[];
  conversionMetric: AttributionMetric;
  setConversionMetric: (m: AttributionMetric) => void;
  attributionModel: AttributionType;
  setAttributionModel: (m: AttributionType) => void;
  publisherConversion: ApiMetric;
  setPublisherConversion: (m: ApiMetric) => void;
  filters: ApiChartAttribute[];
  currentStep: number;
  step: number;
  onTitleClick?: () => void;
  paidChannelAttributes: ApiChartAttribute[];
  onMetricClick: (m: ApiMetric, d: ApiDimension, dv: string) => void;
  mode: "explore" | "analyze";
}

function CampaignAnalysisTable({
  title,
  firstCols,
  onClick,
  selecteValues,
  conversionMetric,
  setConversionMetric,
  attributionModel,
  setAttributionModel,
  publisherConversion,
  setPublisherConversion,
  filters,
  step,
  currentStep,
  onTitleClick,
  paidChannelAttributes,
  onMetricClick,
  mode,
}: ICampaignAnalysisTableProps) {
  const { chartOutput } = useFromToContext();
  const { selectedProject } = useSelectedProject();
  const [anchorMetricEl, setAnchorMetricEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorModelEl, setAnchorModelEl] = useState<null | HTMLElement>(null);
  const [anchorConversionEl, setAnchorConversionEl] =
    useState<null | HTMLElement>(null);

  const onMetricRefChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorMetricEl(event.currentTarget);
    },
    []
  );
  const onModelRefChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorModelEl(event.currentTarget);
    },
    []
  );
  const onConversionRefChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorConversionEl(event.currentTarget);
    },
    []
  );

  const active = useMemo(() => step === currentStep, [step, currentStep]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [scope, animate] = useAnimate();
  const dimensions = useMemo(() => firstCols.map((f) => f.key), [firstCols]);
  const attributionMetric = useMemo(() => {
    return (
      metricByAttribution(attributionModel, conversionMetric) ||
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT
    );
  }, [attributionModel, conversionMetric]);

  const attributionApiMetrics = useMemo(
    () => [
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE,
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROAS,
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI,

      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE,
      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,

      ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE,
      ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS,
      ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI,

      ApiMetric.METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LINEAR_SALES_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LINEAR_REVENUE,
      ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROAS,
      ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROI,

      ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE,
      ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS,
      ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI,

      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE,
      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS,
      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI,
    ],
    []
  );

  const attributionMetrics = useMemo(() => {
    return {
      [AttributionMetric.MACRO]: "Actual Conversions",
      [AttributionMetric.SALES]: "Actual Sales",
      [AttributionMetric.REVENUE]: "Actual Revenue",
      [AttributionMetric.ROI]: "Actual ROI",
      [AttributionMetric.ROAS]: "Actual ROAS",
    };
  }, []);
  const attributionModels = useMemo(() => {
    return {
      [AttributionType.FIRST]: "First Click",
      [AttributionType.LAST]: "Last Click",
      [AttributionType.LAST_NON]: "Last Non-Direct",
      [AttributionType.LINEAR]: "Linear",
      [AttributionType.POSITION]: "Position",
      [AttributionType.IMPACTED]: "Impacted",
    };
  }, []);
  const publisherConversions = useMemo(() => {
    return {
      [ApiMetric.METRIC_PUBLISHER_CONVERSIONS]: "Conversions",
      [ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS]: "All Conversions",
    };
  }, []);

  const chart: IChart = useMemo(
    () => ({
      dimension: dimensions,
      yAxisKeys: [
        {
          key: "l",
          fields: [
            ApiMetric.METRIC_PUBLISHER_CLICKS,
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_PUBLISHER_CLICKS_DIFFERENCE,

            ApiMetric.METRIC_PUBLISHER_IMPRESSIONS,
            ApiMetric.METRIC_PUBLISHER_CTR,
            ApiMetric.METRIC_CALCULATED_CTR,
            ApiMetric.METRIC_PUBLISHER_CPC,
            ApiMetric.METRIC_SPEND,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
            ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
            ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,

            ApiMetric.METRIC_PUBLISHER_CONVERSIONS,
            ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS,
            ...attributionApiMetrics,
          ],
        },
      ],
      attributes: [...paidChannelAttributes, ...filters],
      displayOptions: {
        noFilters: true,
        noTitle: true,
        noAttributionToggle: true,
        rowsPerPage: 0,
        tableGroupings: [
          {
            name: "Traffic Metrics",
            metrics: [
              ApiMetric.METRIC_PUBLISHER_CLICKS,
              ApiMetric.METRIC_SESSIONS_COUNT,
              ApiMetric.METRIC_PUBLISHER_CLICKS_DIFFERENCE,
            ],
          },
          {
            name: "Click Through Metrics",
            metrics: [
              ApiMetric.METRIC_PUBLISHER_IMPRESSIONS,
              ApiMetric.METRIC_PUBLISHER_CTR,
              ApiMetric.METRIC_CALCULATED_CTR,
            ],
          },
          {
            name: "Cost Metrics",
            metrics: [ApiMetric.METRIC_PUBLISHER_CPC, ApiMetric.METRIC_SPEND],
          },
          {
            name: "Engagement Metrics",
            metrics: [
              ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
              ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
              ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
            ],
          },
          {
            name: attributionMetrics[conversionMetric],
            metrics: [attributionMetric],
          },
        ],
        tableColWidths: {
          [attributionMetric]: 180,
        },
      },
    }),
    [
      filters,
      dimensions,
      paidChannelAttributes,
      attributionApiMetrics,
      attributionMetric,
      attributionMetrics,
      conversionMetric,
    ]
  );

  const { chartData } = useChartData(
    selectedProject,
    chart,
    step < currentStep ? chartOutput : null
  );

  const combinedData = useMemo(() => {
    return chartData?.data || [];
  }, [chartData?.data]);

  const tableCustomHeaderRender = useMemo(
    () => ({
      [ApiDimension.DIMENSION_SOURCE]: (props) => {
        return <>Platforms</>;
      },
      [publisherConversion]: (props) => {
        return (
          <>
            <Stack direction="row" alignItems={"center"}>
              <strong>{publisherConversions[publisherConversion]}</strong>
              <IconButton onClick={onConversionRefChange}>
                <ArrowDropDown
                  sx={{
                    transition: "transform 0.15s linear",
                    transform: Boolean(anchorConversionEl)
                      ? "rotate(180deg)"
                      : undefined,
                  }}
                />
              </IconButton>
            </Stack>
          </>
        );
      },
      [attributionMetrics[conversionMetric]]: () => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <strong>{attributionMetrics[conversionMetric]}</strong>
            <IconButton onClick={onMetricRefChange}>
              <ArrowDropDown
                sx={{
                  transition: "transform 0.15s linear",
                  transform: Boolean(anchorMetricEl)
                    ? "rotate(180deg)"
                    : undefined,
                }}
              />
            </IconButton>
          </Stack>
        );
      },
      [attributionMetric]: () => {
        return (
          <Stack direction="row" alignItems={"center"} sx={{ width: 400 }}>
            <strong>{attributionModels[attributionModel]}</strong>
            <IconButton onClick={onModelRefChange}>
              <ArrowDropDown
                sx={{
                  transition: "transform 0.15s linear",
                  transform: Boolean(anchorModelEl)
                    ? "rotate(180deg)"
                    : undefined,
                }}
              />
            </IconButton>
          </Stack>
        );
      },
    }),
    [
      publisherConversions,
      publisherConversion,
      onConversionRefChange,
      anchorConversionEl,
      attributionMetrics,
      conversionMetric,
      anchorMetricEl,
      onMetricRefChange,
      anchorModelEl,
      attributionModel,
      attributionModels,
      onModelRefChange,
      attributionMetric,
    ]
  );

  const tableCustomCellRender = useMemo(
    () => ({
      [firstCols[0].key as ApiDimension]: ({ value, ...rest }) => {
        return (
          <Box
            sx={{ display: "flex", height: "100%", cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              onClick && onClick(value);
            }}
          >
            <Stack
              direction={"row"}
              spacing={1}
              alignItems="center"
              justifyContent={"flex-start"}
              pl={1}
            >
              {(firstCols[0].key as ApiDimension) ===
                ApiDimension.DIMENSION_SOURCE && (
                <ProviderIcon provider={value} />
              )}
              <Typography>{value}</Typography>
            </Stack>
          </Box>
        );
      },
    }),
    [firstCols, onClick]
  );

  const hiddenCols = useMemo(
    () =>
      [
        ...attributionApiMetrics,
        ApiMetric.METRIC_PUBLISHER_CONVERSIONS,
        ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS,
      ].filter((a) => ![attributionMetric, publisherConversion].includes(a)),
    [attributionMetric, publisherConversion, attributionApiMetrics]
  );

  useEffect(() => {
    if (isDataLoaded && active) {
      animate(scope.current, { opacity: 1 }, { delay: 0.5 });
    }
  }, [isDataLoaded, animate, scope, active]);

  const toSalesClick = useMemo(() => {
    return {
      text: "Click To See Last Click Sales",
      fn: (dimension) =>
        onMetricClick(attributionMetric, firstCols[0].key, `${dimension}`),
      icon: <OpenInNew />,
    };
  }, [attributionMetric, firstCols, onMetricClick]);

  return (
    <AnimatePresence>
      <StyledPopper
        open={Boolean(anchorConversionEl)}
        anchorEl={anchorConversionEl}
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setAnchorConversionEl(null)}>
            <List disablePadding>
              <ListItem>
                <StyledListTitle primary="Publisher Conversions" />
              </ListItem>
              <Divider />
              {Object.keys(publisherConversions).map((k) => (
                <ListItem key={k} disablePadding>
                  <StyledListButton
                    onClick={() => {
                      setPublisherConversion(k as ApiMetric);
                      setAnchorConversionEl(null);
                    }}
                  >
                    <ListItemText primary={publisherConversions[k]} />
                    {publisherConversion === k && (
                      <ListItemIcon>
                        <Check color="success" />
                      </ListItemIcon>
                    )}
                  </StyledListButton>
                </ListItem>
              ))}
            </List>
          </ClickAwayListener>
        </Paper>
      </StyledPopper>
      <StyledPopper open={Boolean(anchorMetricEl)} anchorEl={anchorMetricEl}>
        <Paper>
          <ClickAwayListener onClickAway={() => setAnchorMetricEl(null)}>
            <List disablePadding>
              <ListItem>
                <StyledListTitle primary="Conversion Metrics" />
              </ListItem>
              <Divider />
              {Object.keys(attributionMetrics).map((k) => (
                <ListItem key={k} disablePadding>
                  <StyledListButton
                    onClick={() => {
                      setConversionMetric(k as AttributionMetric);
                      setAnchorMetricEl(null);
                    }}
                  >
                    <ListItemText primary={attributionMetrics[k]} />
                    {conversionMetric === k && (
                      <ListItemIcon>
                        <Check color="success" />
                      </ListItemIcon>
                    )}
                  </StyledListButton>
                </ListItem>
              ))}
            </List>
          </ClickAwayListener>
        </Paper>
      </StyledPopper>
      <StyledPopper open={Boolean(anchorModelEl)} anchorEl={anchorModelEl}>
        <Paper>
          <ClickAwayListener onClickAway={() => setAnchorModelEl(null)}>
            <List disablePadding>
              <ListItem>
                <StyledListTitle primary="Attribution Models" />
              </ListItem>
              <Divider />
              {Object.keys(attributionModels).map((k) => (
                <ListItem key={k} disablePadding>
                  <StyledListButton
                    onClick={() => {
                      setAttributionModel(k as AttributionType);
                      setAnchorModelEl(null);
                    }}
                  >
                    <AnalyticsAttributionIcon model={k as AttributionType} />

                    <ListItemText primary={attributionModels[k]} />
                    {attributionModel === k && (
                      <ListItemIcon>
                        <Check color="success" />
                      </ListItemIcon>
                    )}
                  </StyledListButton>
                </ListItem>
              ))}
            </List>
          </ClickAwayListener>
        </Paper>
      </StyledPopper>
      <Stack sx={{ height: mode === "analyze" ? "calc(100% - 69px)" : "100%" }}>
        {active ? (
          <>
            {!isDataLoaded && <Loading />}
            <Box
              display={"flex"}
              sx={{ height: "100%", width: "100%", opacity: 0 }}
              ref={scope}
            >
              <AnalyticsChart
                onDataChange={() => {
                  setIsDataLoaded(true);
                }}
                tableCustomHeaderRender={tableCustomHeaderRender}
                tableCustomCellRender={tableCustomCellRender}
                tableHiddenCols={hiddenCols}
                tableSx={{
                  top: -1,
                  bottom: 1,
                  right: 0,
                  left: 0,
                }}
                chart={{
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  ...chart,
                }}
                onClick={{
                  [ApiMetric.METRIC_SESSIONS_COUNT as string]: [
                    {
                      text: "Click To See Visits",
                      fn: (dimension) =>
                        onMetricClick(
                          ApiMetric.METRIC_SESSIONS_COUNT,
                          firstCols[0].key,
                          `${dimension}`
                        ),

                      icon: <OpenInNew />,
                    },
                  ],
                  [attributionMetric as string]: [toSalesClick],
                }}
              />
            </Box>
          </>
        ) : step < currentStep ? (
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <StyledTopTableCell onClick={onTitleClick}>
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    noWrap
                    color={"inherit"}
                  >
                    {title}
                  </Typography>
                </StyledTopTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedData.map((d, i) => (
                <CampaignAnalysisTableRow
                  current={false}
                  key={i}
                  data={d}
                  firstCols={firstCols}
                  selecteValues={selecteValues}
                  dimension={dimensions[0]}
                  onClick={onClick}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <StyledTopTableCell onClick={onTitleClick}>
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    noWrap
                    color={"inherit"}
                  >
                    {title}
                  </Typography>
                </StyledTopTableCell>
              </TableRow>
            </TableHead>
          </Table>
        )}
      </Stack>
    </AnimatePresence>
  );
}

export default withPaidChannelAttributes(CampaignAnalysisTable);
