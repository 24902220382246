import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Box, styled } from "@mui/material";
import AnalyticsChart from "features/analytics/components/AnalyticsChart";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getSessionById } from "services/session";

const StyledWrapper = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 120px)",
  transform: "translate(-8px, -27px)!important",
  width: "calc(100% + 17px)",
  "& > .MuiBox-root": {
    height: "100%",
  },
}));

interface ISalesTableProps {
  attributes?: ApiChartAttribute[];
}

function SalesTable({ attributes }: ISalesTableProps) {
  const history = useHistory();

  const onVisitClick = useCallback(
    async (v) => {
      const res = await getSessionById(Number(v));
      history.push(`../user-dashboard/${res.leadId}`);
    },
    [history]
  );

  return (
    <StyledWrapper>
      <AnalyticsChart
        forceNoComparison
        onClick={{
          [ApiDimension.DIMENSION_SESSION_ID]: onVisitClick,
        }}
        chart={{
          name: "Sales Report",
          dimension: [
            ApiDimension.DIMENSION_TRANSACTION_ID,
            ApiDimension.DIMENSION_SESSION_START_DATE,
            ApiDimension.DIMENSION_SESSION_ID,
            ApiDimension.DIMENSION_DEVICE,
            ApiDimension.DIMENSION_BROWSER,
          ],
          template: ApiChartTemplate.TABLE,
          type: ApiChartType.TABLE,
          orderBy: `${ApiDimension.DIMENSION_SESSION_START_DATE}`,
          attributes: attributes || [],
          yAxisKeys: [
            {
              key: "l",
              fields: [
                ApiMetric.METRIC_SALES_REVENUE,
                ApiMetric.METRIC_SESSION_REVENUE,
                ApiMetric.METRIC_SESSION_PROFIT,
              ],
            },
          ],
          displayOptions: {
            noFilters: true,
            noTitle: true,
            noAttributionToggle: true,
            rowsPerPage: 20,
            tableColWidths: {
              [ApiDimension.DIMENSION_TRANSACTION_ID]: 300,
              [ApiDimension.DIMENSION_DEVICE]: 96,
              [ApiDimension.DIMENSION_BROWSER]: 146,
            },
            tablePinAt: 2,
          },
        }}
      />
    </StyledWrapper>
  );
}

export default SalesTable;
