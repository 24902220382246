import {
  getCustomComparison,
  getPeriodComparison,
  getYearComparison,
} from "Helpers/dates";
import { useLocalAndSearchState } from "Hooks/useLocalAndSearchState";
import moment, { Moment } from "moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface IFromToOutput {
  from: string | undefined;
  to: string | undefined;
  lastNDays: number | undefined;
}
export interface IFromToChartOutput {
  from: Date | undefined;
  to: Date | undefined;
  lastNDays: number | undefined;
}
export type TComparisonMode = "period" | "year" | "custom";

type TFromToContext = {
  from: Moment | null;
  setFrom: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  to: Moment | null;
  setTo: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  lastNDays: number | null;
  setLastNDays: React.Dispatch<React.SetStateAction<number | null>>;
  output: IFromToOutput;
  chartOutput: IFromToChartOutput;
  chartComparisonOutput: IFromToChartOutput | null;
  comparisonWindowDays: number | undefined;
  calculatedFrom: Moment;
  calcNumberOfDays: (v: IFromToChartOutput) => number;
  recordedIn: (v: IFromToChartOutput) => string;
  comparison: boolean;
  setComparison: React.Dispatch<React.SetStateAction<boolean>>;
  comparisonMode: TComparisonMode;
  setComparisonMode: React.Dispatch<React.SetStateAction<TComparisonMode>>;
  cFrom: Moment | null;
  setCFrom: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
};

export const FromToContext = createContext<TFromToContext>({
  cFrom: null,
  setCFrom: () => null,
  from: null,
  setFrom: () => null,
  to: null,
  setTo: () => null,
  lastNDays: null,
  setLastNDays: () => null,
  output: {
    from: undefined,
    to: undefined,
    lastNDays: undefined,
  },
  chartOutput: {
    from: undefined,
    to: undefined,
    lastNDays: undefined,
  },
  chartComparisonOutput: null,
  comparisonWindowDays: undefined,
  calculatedFrom: moment(),
  calcNumberOfDays: () => 0,
  recordedIn: () => "",
  comparison: false,
  setComparison: () => null,
  comparisonMode: "period",
  setComparisonMode: () => null,
});

export const useFromToContext = () => useContext(FromToContext);

const FromToProvider = ({
  children,
  initialLastNDays,
}: {
  initialLastNDays?: number;
  children: React.ReactNode;
}) => {
  const [chartOutput, setChartOutput, isLoaded] = useLocalAndSearchState<any>(
    "co",
    null
  );
  const [from, setFrom] = useState<moment.Moment | null>(
    chartOutput?.from ? moment(chartOutput.from).utc() : null
  );
  const [cFrom, setCFrom] = useState<moment.Moment | null>(
    chartOutput?.cFrom ? moment(chartOutput.cFrom).utc() : null
  );
  const [to, setTo] = useState<moment.Moment | null>(
    chartOutput?.to ? moment(chartOutput.to).utc() : null
  );
  const [lastNDays, setLastNDays] = useState<number | null>(
    chartOutput?.lastNDays
  );
  const [comparison, setComparison] = useState(chartOutput?.comparison);
  const [comparisonMode, setComparisonMode] = useState<TComparisonMode>(
    chartOutput?.comparisonMode || "period"
  );
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    if (!isLoaded || !isSet) {
      return;
    }
    const newChartOutput = {
      from: from ? from.clone().startOf("day").format() : undefined,
      cFrom: cFrom ? cFrom.clone().startOf("day").format() : undefined,
      to: to ? to.clone().endOf("day").format() : undefined,
      lastNDays: lastNDays || undefined,
      comparison,
      comparisonMode,
    };

    if (JSON.stringify(newChartOutput) !== JSON.stringify(chartOutput)) {
      setChartOutput(newChartOutput);
    }
  }, [
    from,
    cFrom,
    to,
    lastNDays,
    chartOutput,
    setChartOutput,
    isLoaded,
    comparison,
    isSet,
    comparisonMode,
  ]);

  useEffect(() => {
    if (isLoaded && !isSet) {
      setLastNDays(
        chartOutput?.from
          ? null
          : chartOutput?.lastNDays || initialLastNDays || 30
      );
      setFrom(chartOutput?.from ? moment(chartOutput.from).utc() : null);
      setCFrom(chartOutput?.cFrom ? moment(chartOutput.cFrom).utc() : null);
      setTo(chartOutput?.to ? moment(chartOutput.to).utc() : null);
      setComparison(chartOutput?.comparison);
      setComparisonMode(chartOutput?.comparisonMode || "period");
      setIsSet(true);
    }
  }, [isLoaded, chartOutput, setLastNDays, initialLastNDays, isSet]);

  const comparisonWindowDays = useMemo(() => {
    if (lastNDays) {
      return lastNDays;
    }
    const duration = moment.duration(moment(to).diff(from));
    const diff = Math.round(duration.asDays());
    return diff;
  }, [lastNDays, from, to]);

  const calcNumberOfDays = useCallback((output: IFromToChartOutput) => {
    if (output.lastNDays) {
      return output.lastNDays;
    }
    const duration = moment.duration(moment(output.to).diff(output.from));
    const diff = Math.round(duration.asDays());
    return diff + 1;
  }, []);

  const compToFrom = useMemo(() => {
    switch (comparisonMode) {
      case "period":
        return getPeriodComparison(comparisonWindowDays, from, to);
      case "year":
        return getYearComparison(from, to);
      case "custom":
        return getCustomComparison(comparisonWindowDays, cFrom);
      default:
        return {
          lastNDays: 30,
          from: undefined,
          to: undefined,
        };
    }
  }, [comparisonMode, comparisonWindowDays, from, to, cFrom]);

  const calculatedFrom = useMemo(() => {
    return from || moment().utc().subtract(lastNDays, "days").startOf("day");
  }, [from, lastNDays]);

  const recordedIn = useCallback(
    (output: IFromToChartOutput) => {
      return `recorded in the Last ${calcNumberOfDays(output)} days`;
    },
    [calcNumberOfDays]
  );

  const state = useMemo(() => {
    return {
      from,
      setFrom,
      to,
      setTo,
      lastNDays,
      setLastNDays,
      output: {
        from: from ? from?.clone().startOf("day").format() : undefined,
        to: to ? to?.clone().endOf("day").format() : undefined,
        lastNDays: lastNDays || undefined,
      },
      chartOutput: {
        from: from ? from?.clone().startOf("day").toDate() : undefined,
        to: to ? to?.clone().endOf("day").toDate() : undefined,
        lastNDays: lastNDays || undefined,
      },
      chartComparisonOutput: comparison
        ? {
            ...compToFrom,
            lastNDays: undefined,
          }
        : null,
      comparisonWindowDays,
      calculatedFrom,
      calcNumberOfDays,
      recordedIn,
      comparison,
      setComparison,
      comparisonMode,
      setComparisonMode,
      cFrom,
      setCFrom,
    };
  }, [
    to,
    setTo,
    from,
    setFrom,
    lastNDays,
    setLastNDays,
    compToFrom,
    comparisonWindowDays,
    calculatedFrom,
    calcNumberOfDays,
    recordedIn,
    comparison,
    setComparison,
    comparisonMode,
    setComparisonMode,
    cFrom,
    setCFrom,
  ]);

  return (
    <FromToContext.Provider value={state}>{children}</FromToContext.Provider>
  );
};

export default FromToProvider;
